import { useEffect, useCallback, useState, useRef } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import Autoplay from 'embla-carousel-autoplay';
import { useWindowSize } from '@uidotdev/usehooks';
import {
    OPPORTUNITY_CAROUSEL_DESKTOP_MARGIN,
    OPPORTUNITY_CAROUSEL_MOBILE_MARGIN,
    TWEEN_FACTOR_BASE,
} from '@components/Homepage/OpportunitiesCarousel/utils/constants';
import { numberWithinRange } from '@components/Homepage/OpportunitiesCarousel/utils/helpers';

const useOpportunitiesCarousel = ({ opportunities }) => {
    const [activeSlide, setActiveSlide] = useState(0);
    const tweenFactor = useRef(0);
    const tweenNodes = useRef([]);
    const { width } = useWindowSize();
    
    const [emblaRef, emblaApi] = useEmblaCarousel(
        {
            loop: true,
            align: 'center',
        },
    );

    const scrollPrev = useCallback(() => {
        emblaApi?.scrollPrev();
    }, [emblaApi]);

    const scrollNext = useCallback(() => {
        emblaApi?.scrollNext();
    }, [emblaApi]);

    const onSelect = useCallback(() => {
        if (!emblaApi) return;
        setActiveSlide(emblaApi.selectedScrollSnap());
    }, [emblaApi]);

    const setTweenNodes = useCallback((emblaApi) => {
        tweenNodes.current = emblaApi.slideNodes().map(slideNode =>
            slideNode.querySelector('.opportunities_carousel__slide__number')
        );
    }, []);

    const setTweenFactor = useCallback((emblaApi) => {
        tweenFactor.current = TWEEN_FACTOR_BASE * emblaApi.scrollSnapList().length;
    }, []);

    const calculateDiffToTarget = (scrollSnap, scrollProgress, engine, slideIndex) => {
        let diffToTarget = scrollSnap - scrollProgress;

        if (engine.options.loop) {
            engine.slideLooper.loopPoints.forEach(loopItem => {
                const target = loopItem.target();
                const sign = Math.sign(target);

                if (slideIndex === loopItem.index && target !== 0) {
                    diffToTarget = sign === -1 ? scrollSnap - (1 + scrollProgress) : scrollSnap + (1 - scrollProgress);
                }
            });
        }

        return diffToTarget;
    };

    const applyTransformations = (tweenNode, diffToTarget, snapIndex, emblaApi, opportunities) => {
        const tweenValue = 1 - Math.abs(diffToTarget * tweenFactor.current);
        const scale = numberWithinRange(tweenValue, 0.9, 1).toString();
        const marginFactor = width < 1280 ? OPPORTUNITY_CAROUSEL_MOBILE_MARGIN : OPPORTUNITY_CAROUSEL_DESKTOP_MARGIN;

        const transformValue = width >= 768 ? `scale(${scale}) translateX(${emblaApi.selectedScrollSnap() === snapIndex ? 0 : diffToTarget > 0 ? marginFactor : -marginFactor}px)`: `scale(${scale})`;

        tweenNode.style.transform = transformValue;
        tweenNode.parentNode.style.zIndex = Math.round(emblaApi.selectedScrollSnap() === snapIndex ? opportunities.length : diffToTarget * -1);

        if (width >= 768) {
            tweenNode.style.boxShadow = emblaApi.selectedScrollSnap() === snapIndex ? '0px 13px 40px 0px rgba(11, 7, 110, 0.10)' : '0px 6px 18px 0px rgba(11, 7, 110, 0.07)';
        }
    };

    const tweenScale = useCallback(
        (emblaApi, eventName) => {
            const engine = emblaApi.internalEngine();
            const scrollProgress = emblaApi.scrollProgress();
            const isScrollEvent = eventName === 'scroll';
            const slidesInView = emblaApi.slidesInView(true);

            emblaApi.scrollSnapList().forEach((scrollSnap, snapIndex) => {
                const slidesInSnap = engine.slideRegistry[snapIndex];

                slidesInSnap.forEach(slideIndex => {
                    if (isScrollEvent && !slidesInView.includes(slideIndex)) return;

                    const diffToTarget = calculateDiffToTarget(scrollSnap, scrollProgress, engine, slideIndex);
                    const tweenNode = tweenNodes.current[slideIndex];

                    applyTransformations(tweenNode, diffToTarget, snapIndex, emblaApi, opportunities);
                });
            });
        },
        [width]
    );

    useEffect(() => {
        if (!emblaApi) return;

        setTweenNodes(emblaApi);
        setTweenFactor(emblaApi);
        tweenScale(emblaApi);

        emblaApi
            .on('select', onSelect)
            .on('reInit', setTweenNodes)
            .on('reInit', setTweenFactor)
            .on('reInit', tweenScale)
            .on('scroll', tweenScale)
            .on('slideFocus', tweenScale);
    }, [emblaApi, tweenScale]);

    return {
        activeSlide,
        emblaRef,
        scrollPrev,
        scrollNext,
    };
};

export default useOpportunitiesCarousel;