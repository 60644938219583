import { cn } from '@ui/atoms/utils/utils';
import { buttonSizes, buttonTypes } from '@ui/atoms/utils/enums';
import Button from '@ui/atoms/design-system/Button';
import { Image, Text } from '@ui/atoms';
import texts from "@components/Homepage/OpportunitiesCarousel/en.json";

const OpportunityCard = ({ details, isActive, handleOnClick, index, width }) => {
    return (
        <div
            className={cn(
                'opportunities_carousel__slide h-max -z-1 w-full min-w-[328px] max-w-[328px]',
                {
                    'min-h-max h-full z-1': isActive,
                    'first:ml-16 last:ml-32': width < 768,
                }
            )}
            key={index}
        >
            <div
                className="w-full h-full overflow-hidden opportunities_carousel__slide__number rounded-xl"
                id={index}
            >
                <div
                    className={cn('text-center p4-medium py-1', {
                        'bg-semantic-success-light text-semantic-success-base':
                            details?.status === texts?.LiveNow,
                        'bg-semantic-info-light text-semantic-info-base':
                            details?.status === texts?.FullySubscribed,
                    })}
                >
                    {details?.status}
                </div>
                <div
                    className="flex flex-col gap-3 p-4 bg-basicWhite"
                    style={{
                        boxShadow:
                            '0px 10px 15px -3px rgba(0, 0, 0, 0.10), 0px 4px 6px -2px rgba(0, 0, 0, 0.05)',
                    }}
                >
                    <div className="flex items-center gap-4">
                        <div className="w-[68px] h-[68px] flex-center">
                            <Image
                                src={details?.image}
                                className="w-full object-scale-down"
                                alt={details?.isin}
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <Text
                                content={details?.title}
                                className={cn(
                                    'text-basicBlack h6-bold line-clamp-2',
                                    {
                                        'line-clamp-none': isActive,
                                    }
                                )}
                            />
                            <Text
                                content={details?.description}
                                className="text-basicBlack p5-regular line-clamp-1"
                            />
                        </div>
                    </div>
                    <hr className="border-gray-200 border-[0.5px]" />
                    <div className="flex items-center justify-between">
                        <div className="flex flex-col gap-1">
                            <Text
                                content={texts?.Yield}
                                className="text-gray-500 p5-regular"
                            />
                            <Text
                                content={`${details?.yield}`}
                                className="text-primary-500 p3-bold"
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <Text
                                content={texts?.Tenure}
                                className="text-gray-500 p5-regular"
                            />
                            <Text
                                content={details?.tenure}
                                className="text-primary-500 p3-bold"
                            />
                        </div>
                        <div className="flex flex-col gap-1">
                            <Text
                                content={texts?.MinInvestment}
                                className="text-gray-500 p5-regular"
                            />
                            <Text
                                content={details?.minInvestment}
                                className="text-primary-500 p3-bold"
                            />
                        </div>
                    </div>
                </div>
                <div className="flex flex-col bg-basicWhite">
                    <div className="flex items-start gap-4 px-4 py-3 bg-gray-50">
                        <div className="flex justify-between flex-1 items-start gap-0.5">
                            <div className="flex flex-col gap-1 w-1/2">
                                <Text
                                    content={texts?.RatingCategory}
                                    className="self-stretch text-gray-600 p5-regular"
                                />
                                <Text
                                    content={`${details?.ratingCategory}`}
                                    className="self-stretch text-basicBlack p4-medium"
                                />
                            </div>
                            <div className="flex flex-col gap-1 w-1/2">
                                <Text
                                    content={texts?.Isin}
                                    className="self-stretch text-gray-600 p5-regular"
                                />
                                <Text
                                    content={`${details?.isin}`}
                                    className="self-stretch text-basicBlack p4-medium"
                                />
                            </div>
                        </div>
                    </div>
                    {isActive && (
                        <div className="px-4 pt-2 pb-4">
                            <Button
                                buttonText={details?.canInvest ? texts?.InvestNow : texts?.ViewOpportunity}
                                buttonType={buttonTypes.SECONDARY}
                                buttonSize={buttonSizes.SMALL}
                                widthClass={'w-full'}
                                onClick={() => handleOnClick(details.opportunityId, details?.canInvest)}
                            />
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default OpportunityCard;