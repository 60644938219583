import { isUnlistedOpportunityType } from "@helpers/opportunity";

export const getRiskCategoryImage = (riskCategory) => {
    return riskCategory && `/images/risk/${riskCategory}Risk.svg`;
};

export const getRiskCategoryName = (riskCategory) => {
    switch (riskCategory) {
        case 'VeryLow':
            return 'Very Low';
        case 'Low':
            return 'Low';
        case 'Medium':
            return 'Medium';
        case 'High':
            return 'High';
        case 'VeryHigh':
            return 'Very High';
        default:
            return;
    }
};


export const getKeyAttributesValue = (opportunity, label) => {
    switch (label) {
        case 'Rating':
            return opportunity['rating'];
        case 'Mode of Issue':
            return opportunity['modeOfIssue'];
        case 'Tax Saving':
            return opportunity['taxSaving'] ? 'Yes' : 'No';
        case 'Nature of Instrument':
            return isUnlistedOpportunityType(opportunity['type']) ? 'Unlisted' : 'Listed';
        case "Collateral Security":
            return opportunity['collateralSecurity'];
        case 'Seniority':
            return opportunity['seniority'];
        default:
            return ''
    }
}

export const getRiskRatingName = (opportunityRiskRatings) => {
    return opportunityRiskRatings.counterParty.name + " " + opportunityRiskRatings.rating
}