import { Image } from '@ui/atoms';
import { cn } from '@ui/atoms/utils/utils';
import React, { useCallback, useEffect, useState } from 'react';

export const usePrevNextButtons = (emblaApi) => {
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollPrev();
    }, [emblaApi]);

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return;
        emblaApi.scrollNext();
    }, [emblaApi]);

    const onSelect = useCallback((emblaApi) => {
        setPrevBtnDisabled(!emblaApi.canScrollPrev());
        setNextBtnDisabled(!emblaApi.canScrollNext());
    }, []);

    useEffect(() => {
        if (!emblaApi) return;

        onSelect(emblaApi);
        emblaApi.on('reInit', onSelect).on('select', onSelect);
    }, [emblaApi, onSelect]);

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
    };
};

export const PrevButton = (props) => {
    const { children, className, ...restProps } = props;

    return (
        <button
            className={cn(
                'appearance-none  p-2.5 border border-primary-400 rounded-lg top-1/2 left-0 transform xl:-translate-y-1/2',
                className
            )}
            type="button"
            {...restProps}
        >
            <Image
                src="/images/arrow-left-primary.svg"
                alt="left"
                className="w-4 h-4"
            />
            {children}
        </button>
    );
};

export const NextButton = (props) => {
    const { children, className, ...restProps } = props;

    return (
        <button
            className={cn(
                'appearance-none  p-2.5 border border-primary-400 rounded-lg top-1/2 right-0 transform xl:-translate-y-1/2',
                className
            )}
            type="button"
            {...restProps}
        >
            <Image
                src="/images/arrow-left-primary.svg"
                alt="left"
                className="w-4 h-4 -rotate-180"
            />
            {children}
        </button>
    );
};
